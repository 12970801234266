<template>
  <div class="gmv-upload-modal">
    <b-modal
      class="gmv-upload-modal-container"
      id="uploadModal"
      scrollable
      title="Suba sus archivos"
    >
      <template>
        <el-radio-group
          v-if="$route.path.toLowerCase().includes('administrador')"
          class="radio-banks"
          v-model="parametersBanks"
        >
          <el-radio
            border
            size="small"
            v-for="bank in banksArray"
            :label="bank"
            :key="bank.id"
            >{{ bank.name }}</el-radio
          >
        </el-radio-group>
      </template>
      <div
        v-loading="Loading"
        element-loading-text="Subiendo..."
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(245 , 229, 229, 0.8)"
        style="width: 100%"
      >
        <FileUpload
          :auto="false"
          accept=".xlsx,.prt,.xlsb,.txt,.csv"
          :maxFileSize="100000000"
          @select="filesChange"
          @clear="clearFile"
          :fileLimit="1"
          chooseLabel="haz clic para cargar"
          :showCancelButton="false"
          :showUploadButton="false"
        >
          <template #empty>
            <i class="el-icon-upload fa-6x" style="color: #b3282d"></i>
            <div class="el-upload__text">Suelta tu archivo aquí</div>
          </template>
        </FileUpload>
        <div slot="tip" class="el-upload__tip">
          Solo archivos XLSB, XLSX, TXT, CSV con un tamaño menor a 100Mb
        </div>
        <hr />
        <div v-if="parametersBanks.name.toLowerCase() === 'santander'">
          <b-input
            required
            title="Este campo es obligatorio, solo números"
            aria-label="required"
            name="campaña"
            size="sm"
            v-model="campaña"
            type="number"
            placeholder="Campaña*"
          ></b-input>
        </div>
        <div v-else-if="$route.path.toLowerCase().includes('santander')">
          <b-input
            required
            title="Este campo es obligatorio, solo números"
            aria-label="required"
            name="campaña"
            autocomplete="off"
            size="sm"
            v-model="campaña"
            type="number"
            placeholder="Campaña*"
          ></b-input>
        </div>
      </div>
      <template #modal-footer>
        <div class="gmv-upload-modal-button"></div>
      </template>
      <div class="button-upload-files">
        <div
          v-if="$route.path.toLowerCase().includes('administrador')"
          class="button-upload-files"
        >
          <template
            v-if="
              $route.path.toLowerCase().includes('administrador') &&
              parametersBanks
            "
          >
            <el-radio-group
              v-model="parametersBancolombia"
              v-if="parametersBanks.name.toLowerCase() === 'bancolombia'"
            >
              <el-radio
                v-for="(queryBancolombia, index) in queriesBancolombia"
                :label="queryBancolombia"
                :key="index"
                >{{ queryBancolombia.label }}</el-radio
              >
            </el-radio-group>
          </template>
          <Button
            label="Subir archivos"
            class="gmv-uploadmodal-button__btn1"
            @click="uploadFiles"
          />
        </div>
        <div
          v-if="$route.path.toLowerCase().includes('bancolombia')"
          class="button-upload-files"
        >
          <template>
            <el-radio-group v-model="parametersBancolombia">
              <el-radio
                v-for="(queryBancolombia, index) in queriesBancolombia"
                :label="queryBancolombia"
                :key="index"
                >{{ queryBancolombia.label }}</el-radio
              >
            </el-radio-group>
          </template>
          <Button
            label="Subir archivos"
            class="gmv-uploadmodal-button__btn1"
            @click="uploadFilesBancolombia"
          />
        </div>
        <div
          v-else-if="$route.path.toLowerCase().includes('santander')"
          class="button-upload-files"
        >
          <template> </template>
          <Button
            label="Subir archivos"
            class="gmv-uploadmodal-button__btn1"
            @click="uploadFilesSantander"
          />
        </div>
        <div
          v-else-if="$route.path.toLowerCase().includes('bbva')"
          class="button-upload-files"
        >
          <template> </template>
          <Button
            label="Subir archivos"
            class="gmv-uploadmodal-button__btn1"
            @click="uploadFilesBbva"
          />
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
import FileUpload from "primevue/fileupload";
import { Loading } from "element-ui";
import Button from "primevue/button";
import queryVariablesBancolombia from "@/utils/uploaderVariablesBancolombia";
import queryVariablesSantander from "@/utils/uploaderVariablesSantander";
import queryVariablesBbva from "@/utils/uploaderVariablesBbva";
export default {
  inject: ["reload"],
  name: "FileModal",
  data() {
    return {
      banks: {
        name: null,
      },
      campaña: null,
      files: [],
      Loading: false,
      parametersBanks: null,
      banksArray: [],
      parametersBancolombia: queryVariablesBancolombia[0],
      queriesBancolombia: queryVariablesBancolombia,
      parametersSantander: queryVariablesSantander[0],
      queriesSantander: queryVariablesSantander,
      parametersBbva: queryVariablesBbva[0],
      queriesBbva: queryVariablesBbva,
    };
  },
  components: {
    Loading,
    FileUpload,
    Button,
  },
  async created() {
    this.banksArray = this.$store.getters["banks/showBanks"];
    this.parametersBanks = this.banksArray[0];
  },
  methods: {
    filesChange(fileList) {
      fileList.files.forEach((file) => {
        this.files.push(file);
      });
    },
    clearFile() {
      this.files = [];
    },
    async uploadFiles() {
      this.Loading = true;
      let data = new FormData();
      this.files.forEach((file) => {
        data.append("file", file);
      });
      if (this.parametersBanks.name.toLowerCase() === "bancolombia") {
        let result = await this.$store.dispatch(
          "upload/uploadFilesBancolombia",
          {
            body: data,
            selectedOption: this.parametersBancolombia.value,
          }
        );
        if (this.parametersBancolombia.value == "contener") {
          localStorage.setItem("ruta_base", result.data.directory);
        } else if (this.parametersBancolombia.value == "revolvente") {
          localStorage.setItem("ruta_base", result.data.directory);
        } else if (this.parametersBancolombia.value == "desmontes") {
          localStorage.setItem("ruta_desmontes", result.data.directory);
        } else if (this.parametersBancolombia.value == "rechazos_simulacion") {
          localStorage.setItem("ruta_rechazos", result.data.directory);
        }
      } else if (this.parametersBanks.name.toLowerCase() === "santander") {
        const campaña = this.campaña;
        let result = await this.$store.dispatch("upload/uploadFilesSantander", {
          data,
          campaña,
          selectedOption: "telefonos",
        });
        if (this.parametersSantander.value == "telefonos") {
          localStorage.setItem("cellphones_route", result.data.directory);
        }
      } else if (this.parametersBanks.name.toLowerCase() === "bbva") {
        let result = await this.$store.dispatch("upload/uploadFilesBbva", {
          data,
          selectedOption: "pre_aprobados",
        });
        if (this.parametersBbva.value == "pre_aprobados") {
          localStorage.setItem("commercial_route", result.data.directory);
        }
      }
      this.reload({ name: "ProcessedFiles" });
      this.Loading = false;
    },
    async uploadFilesBancolombia() {
      this.Loading = true;
      let data = new FormData();
      this.files.forEach((file) => {
        data.append("file", file);
      });
      let result = await this.$store.dispatch("upload/uploadFilesBancolombia", {
        body: data,
        selectedOption: this.parametersBancolombia.value,
      });
      this.Loading = false;
      if (this.parametersBancolombia.value == "contener") {
        localStorage.setItem("ruta_base", result.data.directory);
      } else if (this.parametersBancolombia.value == "revolvente") {
        localStorage.setItem("ruta_base", result.data.directory);
      } else if (this.parametersBancolombia.value == "desmontes") {
        localStorage.setItem("ruta_desmontes", result.data.directory);
      } else if (this.parametersBancolombia.value == "rechazos_simulacion") {
        localStorage.setItem("ruta_rechazos", result.data.directory);
      }
      this.reload({ name: "ProcessedFiles" });
    },
    async uploadFilesSantander() {
      this.Loading = true;
      let data = new FormData();
      this.files.forEach((file) => {
        data.append("file", file);
      });
      const campaña = this.campaña;
      let result = await this.$store.dispatch("upload/uploadFilesSantander", {
        data,
        campaña,
        selectedOption: "telefonos",
      });
      this.Loading = false;
      if (this.parametersSantander.value == "telefonos") {
        localStorage.setItem("cellphones_route", result.data.directory);
      }
      this.reload({ name: "ProcessedFiles" });
    },
    async uploadFilesBbva() {
      this.Loading = true;
      let data = new FormData();
      this.files.forEach((file) => {
        data.append("file", file);
      });
      let result = await this.$store.dispatch("upload/uploadFilesBbva", {
        data,
        selectedOption: "pre_aprobados",
      });
      this.Loading = false;
      if (this.parametersBbva.value == "pre_aprobados") {
        localStorage.setItem("commercial_route", result.data.directory);
      }
      this.reload({ name: "ProcessedFiles" });
    },
  },
};
</script>
<style lang="scss" scoped>
.radio-banks {
  display: flex;
  justify-content: center;
  text-transform: capitalize;
}
::v-deep .el-radio--small.is-bordered {
  padding: 6px;
  border-radius: 3px;
  height: 30px;
  width: 140px;
  text-align: center;
}
::v-deep .modal {
  position: fixed;
  top: 0;
  left: 20px;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}
::v-deep .p-fileupload-empty {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
::v-deep .modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 60rem;
  height: 37rem;
  pointer-events: auto;
  background-color: #fff;
  border-radius: 0.3rem;
}
::v-deep .modal.show .modal-dialog {
  transform: none;
  max-width: 45%;
}
::v-deep button.close {
  border: none;
  background: none;
}
.gmv-upload-modal-form {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  &__inputs {
    border-radius: 8px;
    margin: 10px 0px 15px 0px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    width: 30rem;
  }
  &__title {
    color: $color-primary-company;
    text-align: center;
    padding-top: 15px;
  }
  &__upload {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
  }
  &__letter {
    text-align: center;
    font-size: 25px;
  }
}
::v-deep .p-fileupload .p-fileupload-content {
  padding: 4rem 4rem;
  border: 2px dashed #dee2e6;
  color: #495057;
  border-radius: 10px;
  &:hover {
    border: 2px dashed #b3282d;
  }
}
::v-deep .p-fileupload-filename {
  word-break: initial;
}
::v-deep .p-fileupload-row > div {
  display: flex;
  justify-content: center;
  width: 50%;
}
::v-deep .p-button.p-button-icon-only {
  width: 2.357rem;
  padding: 0.5rem 0;
  background: $color-primary-company;
  border: 1px solid $color-primary-company;
}
::v-deep .p-fileupload-choose:not(.p-disabled) {
  background: transparent;
  color: #b3282d;
  border-color: transparent;
  &:hover {
    background: transparent;
    color: #fd040c;
    border-color: transparent;
  }
}
::v-deep
  .p-fileupload
  .p-fileupload-buttonbar
  .p-button.p-fileupload-choose.p-focus {
  box-shadow: none;
}
::v-deep .p-button:focus {
  box-shadow: none;
}
::v-deep .p-fileupload .p-fileupload-buttonbar {
  background: transparent;
  border: none;
  padding: 0;
}
.gmv-uploadmodal-button {
  display: flex;
  justify-content: space-evenly;
  &__btn1 {
    background-color: $color-primary-company;
    height: 45px;
    border: 1px solid $color-primary-company;
    width: 150px;
    border-radius: 10px;
    margin: 10px;
  }
  &__btn1:hover {
    background-color: red;
    color: black;
    border: 1px solid $color-primary-company;
  }
}
.button-upload-files {
  display: flex;
  align-items: center;
  flex-direction: column;
}
::v-deep .modal-footer {
  display: none;
}
::v-deep .p-fileupload-files {
  font-size: 12px;
}
::v-deep .el-upload__tip {
  font-size: 14px;
  color: #606266;
  margin-top: 7px;
}
</style>