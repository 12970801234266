const sidebarOptions = [
    {
        label: "Procesar",
        icon: "fas fa-paper-plane",
        rol: ["admin", "user"],
        routeParams: { name: "ProcessedFiles"},
        adminRoute: false
    },
    {
        label: "Reportes",
        icon: "far fa-lightbulb",
        rol: ["admin", "user"],
        routeParams: { name: "ReportedFiles"},
        adminRoute: false
    },
    {
        label: "Usuarios",
        icon: "fas fa-user",
        rol: ["admin"],
        routeParams: { name: "UsersList" },
        adminRoute: true
    },
    {
        label: "Bancos",
        icon: "fas fa-university",
        rol: ["admin"],
        routeParams: { name: "BankList" },
        adminRoute: true
    },
]


export default sidebarOptions;