<template>
<div v-if="!$route.path.toLowerCase().includes('select-bank')" class="sidebar-component">
    <FileModal></FileModal>
    <div>
        <ul class="sidebar">
            <li v-for="(option, index) in options" :key="index" @click="changeView(option.routeParams, index)" class="sidebar-item" :class="selectedOption === index ? highlightOption : 'sidebar-item'">
                <div class="sidebar-item__container">
                    <i :class="option.icon" style="color: black"></i>
                    <span class="sidebar-item__label">{{ option.label }}</span>
                </div>
            </li>
        </ul>
    </div>
</div>
</template>

<script>
import FileModal from "@/components/FileModal.vue";
import SidebarOptions from "@/utils/sidebarOptions";

export default {
    name: "MenuSidebar",
    data() {
        return {
            options: null,
            sidebarOptions: null,
            selectedOption: 0,
        };
    },
    components: {
        FileModal,
    },
    created() {
        const mappedOptions = this.transformSidebarOptions();
        this.setSidebarOptions(mappedOptions);
        this.options.forEach((option, index) => {
            if (option.routeParams.name === this.$route.name) {
                this.selectedOption = index;
            }
        });
    },
    computed: {
        highlightOption() {
            return "sidebar-item sidebar-item--selected";
        },
    },
    methods: {
        transformSidebarOptions() {
            return SidebarOptions.map((el) => {
                el.routeParams.params = {
                    bankName: localStorage.getItem("selectedBank"),
                };
                return el;
            });
        },
        setSidebarOptions(options) {
            if (localStorage.getItem("selectedBank") !== "administrador") {
                this.options = options.filter((el) => !el.adminRoute);
                return;
            }
            this.options = options;
        },
        changeView(routeParams, selectedOption) {
            this.selectedOption = selectedOption;
            this.$router.push(routeParams);
        },
    },
};
</script>

<style lang="scss" scoped>
.sidebar-component {
    background-color: white;
    z-index: 2;
    position: fixed;
    top: 95px;
    width: 130px;
    height: 110%;
    overflow-y: auto;
    box-shadow: 0px 10px 10px #5a5959;
    transition: all 0.5s ease;
}

.sidebar {
    padding: 0px;
    margin-top: 10px;
}

.sidebar-item {
    display: flex;
    justify-content: center;
    padding: 12px 0px;
    cursor: pointer;

    &__container {
        width: 80%;
        text-align: left;
    }

    &:hover {
        background: rgb(253, 208, 203);
    }

    &__label {
        margin: 8.5% 10%;
        font-size: 15px;
        color: black;
    }

    &--selected {
        background: rgba(255, 30, 0, 0.5);

        &:hover {
            background: rgba(255, 30, 0, 0.5);
        }
    }
}
</style>
