const queryVariablesBancolombia = [
  { label: "Contener", value: "contener" },
  { label: "Revolvente", value: "revolvente" },
  { label: "Desmontes", value: "desmontes" },
  { label: "Status", value: "status" },
  { label: "Rechazos simulación", value: "rechazos_simulacion" },
];
export default queryVariablesBancolombia;


