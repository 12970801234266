<template>
<div class="gmv-layout">
    <b-navbar fixed="top" class="navbar-gmv" toggleable="sm" style="background-color: #1d2124">
        <header @click="goToHome">
            <img class="img-logo" src="@/assets/Logo_HeaderMV.png" alt="Logo Administrador" />
        </header>
        <h6 @click="goToHome">Grupo MV</h6>
        <b-navbar-toggle target="nav-text-collapse"></b-navbar-toggle>
        <b-navbar-brand></b-navbar-brand>
        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto">
            <div class="info-user">
                <div class="info-user__text">
                    <span class="info-user">{{ userInfo.name }}</span>
                    <small>
                        <b-dropdown right no-caret>
                            <template #button-content>{{ selectedBank }}</template>
                            <b-dropdown-item v-for="bank in userBanks" :key="bank.id" @click="goToBank(bank.name)">
                                <p class="info-user__button">{{ bank.name }}</p>
                            </b-dropdown-item>
                        </b-dropdown>
                    </small>
                </div>
                <div class="info-user__avatar" v-b-popover.hover.top="$store.getters['login/showUserInfo'].name">
                    <b-dropdown right variant="danger" no-caret>
                        <template #button-content><i class="fas fa-user"></i> </template>
                        <b-dropdown-item class="info-user__button" size="sm" @click="$router.push({ name: 'Users' })">Perfil</b-dropdown-item>
                        <b-dropdown-item class="info-user__button" @click="handleLogout">
                            <i class="fas fa-arrow-circle-left"></i> Cerrar
                            sesión</b-dropdown-item>
                    </b-dropdown>
                </div>
            </div>
        </b-navbar-nav>
    </b-navbar>
    <b-navbar fixed="top" class="subnavbar-header">
        <div v-if="$route.path.toLowerCase().includes('administrador')" class="gmv-header-nav">
            <img class="gmv-header-img" src="@/assets/admin.png" alt="Logo Administrador" />
        </div>
        <div v-if="$route.path.toLowerCase().includes('bancolombia')" class="gmv-header-nav">
            <img class="gmv-header-img" src="@/assets/Logo_Bancolombia.png" alt="Logo Bancolombia" />
        </div>
        <div v-else-if="$route.path.toLowerCase().includes('santander')">
            <img class="gmv-header-img" src="@/assets/Logo_Santander.png" alt="Logo Santander" />
        </div>
        <div v-else-if="$route.path.toLowerCase().includes('bbva')">
            <img class="gmv-header-img" src="@/assets/Logo_BBVA.png" alt="Logo BBVA" />
        </div>
        <div v-if="$route.name === 'Uploader'">
            <p class="subnavbar-header__subtitle">Procesar documentos</p>
        </div>
        <div v-if="$route.name === 'Bancolombia'">
            <p class="subnavbar-header__subtitle">Procesar documentos</p>
        </div>
        <div v-if="$route.name === 'Administrador'">
            <p class="subnavbar-header__subtitle">Procesar documentos</p>
        </div>
        <div v-if="$route.name === 'ProcessedFiles'">
            <p class="subnavbar-header__subtitle">Procesar documentos</p>
        </div>
        <div v-if="$route.name === 'ReportedFiles'">
            <p class="subnavbar-header__subtitle">Descargar reportes</p>
        </div>
    </b-navbar>
    <hr class="subnavbar-header__line" />
    <Sidebar v-if="
        !isLoading && $route.name !== 'SelectBank' && $route.name !== 'Users'
      " />
    <b-row class="content">
        <router-view></router-view>
    </b-row>
</div>
</template>

<script>
import Sidebar from "@/layout/Sidebar";
import Badge from "primevue/badge";
import FileModal from "@/components/FileModal.vue";
export default {
    name: "layout",
    components: {
        Sidebar,
        Badge,
        FileModal,
    },
    data() {
        return {
            modalShow: false,
            userInfo: null,
            allBanks: null,
            userBanks: [],
            selectedBank: "",
            isLoading: false,
        };
    },
    async created() {
        this.isLoading = true;
        this.selectedBank = localStorage.getItem("selectedBank");
        this.userInfo = this.$store.getters["login/showUserInfo"];
        const response = await this.$store.dispatch("banks/getBanks");
        this.allBanks = response.data;
        this.filterUserBanks();
        this.isLoading = false;
    },
    watch: {
        "$route.path": function () {
            this.selectedBank = localStorage.getItem("selectedBank");
        },
    },
    methods: {
        async goToBank(bankName) {
            localStorage.setItem("selectedBank", bankName);
            this.isLoading = true;
            this.$router.push({
                name: "ProcessedFiles",
                params: {
                    bankName: bankName
                },
            });
            this.isLoading = false;
            location.reload();
        },
        filterUserBanks() {
            for (let userBankId of this.userInfo.scopes) {
                this.allBanks.forEach((bank) => {
                    if (bank.id == userBankId) {
                        this.userBanks.push(bank);
                    }
                });
            }
        },
        handleLogout() {
            this.$store.commit("banks/cleanBanks");
            localStorage.clear();
            this.$router.push({
                name: "Login"
            });
        },
        goToHome() {
            this.$router.push({
                name: "SelectBank",
            });
            localStorage.setItem("selectedBank", "");
        },
    },
};
</script>

<style lang="scss" scoped>
.gmv-layout {
    margin-bottom: 3rem;
}

.img-logo {
    width: 5rem;
    cursor: pointer;
}

.gmv-header-img {
    margin: 6px 0px;
    width: 6rem;
    border-radius: 5px;
}

.navbar-gmv {
    height: 3rem;
    box-shadow: 2px 5px 5px #303133;
}

.subnavbar-header {
    display: flex;
    top: 52px;
    height: 3rem;
    background: #f2f2f2;
    border: 1px solid #dcdfe6;
    z-index: 100;

    &__subtitle {
        color: black;
        margin: 20px 40px;
        font-size: 20px;
    }

    &__line {
        margin-top: 95px;
    }
}

h6 {
    cursor: pointer;
}

a {
    text-decoration: none;
    background: none;
    color: white;
    margin: 10px;

    &:hover {
        color: #cfd8dc;
    }
}

.info-user {
    display: flex;
    flex-direction: row;

    &__text {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &__avatar {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-left: 15px;
    }

    &__button {
        display: flex;
        flex-direction: column;
        align-items: center;
        border-radius: 5px;
        margin: 8px;
    }
}

::v-deep .btn-danger {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545;
    border-radius: 50%;
    margin: 5px;
}

::v-deep [data-v-7b009027] .btn-link {
    font-weight: 400;
    color: white;
    text-decoration: none;
    padding: 0;
}

::v-deep .btn-link {
    font-weight: 400;
    color: white;
    text-decoration: none;

    &:hover {
        border: none;
    }
}

::v-deep .btn-secondary {
    color: #dc3545;
    padding: 0px;
    background: none;
    border: none;
    font-size: small;
    text-transform: capitalize;

    &:hover {
        background: none;
        border: none;
        color: #dc3545;
    }
}

.container-menu {
    height: 84vh;
}

.option-menu {
    font-size: 20px;
}

.content {
    margin-left: 10rem;
    margin-right: 2rem;
    display: flex;
    flex-direction: column;
}

::v-deep .dropdown-item {
    text-align: center;
    border-radius: 5px;
    text-transform: capitalize;

    &:hover {
        background-color: #f7f1f1;
    }
}
</style>
